import * as React from 'react'
import { useState } from 'react'
import "./style.scss"
import { StaticImage } from "gatsby-plugin-image"

const RecruitPage = ({ title, describe, children, show = true }) => {
  const [visible, setVisible] = useState(show);

  const toggle = () => {
    setVisible(!visible);
  }
  return (
    <div className="textPage ">
      <div className="textPageTitle d-md-none  row justify-content-md-center justify-content-between align-items-center" onClick={toggle}>
        <div>
          <StaticImage
            loading="lazy"
            style={{ marginRight: '15px', verticalAlign: 'middle' }}
            src="../../assets/images/ydd-职位.png"
            quality={95}
            width={20}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="职位"
            title="职位"
          />
          <h5 className="d-inline-block">{title}</h5>
        </div>
        {
          visible ? <p>收起 <span style={{
            marginLeft: '10px',
            display: 'inline-block',
            transform: 'translateY(2px) scaleX(1.5)'
          }}> ^ </span>  </p> : <p>展开  <span style={{
            marginLeft: '10px',
            display: 'inline-block',
            transform: 'rotateZ(180deg) scaleX(1.5)'
          }}> ^ </span> </p>
        }
      </div>
      <h5 className="d-md-block d-none">{title}</h5>
      <p className="d-md-block d-none">{describe}</p>
      <div className="content" style={{ display: visible ? 'block' : 'none' }}>{children}</div>
    </div>
  )
}

export default RecruitPage
